import React from 'react'
import Marco from '../components/marco'
import Container from '@material-ui/core/Container'
import Minipaginas from '../components/minipaginas'
import Minipagina from '../components/minipagina'
import FotoConLeyenda from '../components/foto-con-leyenda'
import Comentarios from '../components/comentarios'
import TextoEnMinipagina from '../components/texto-en-minipagina'
import { Tips } from '../components/estilos/estilos-de-articulo'
import EspaciadorVertical from '../components/espaciador-vertical'
import { graphql } from 'gatsby'
import Navegar from '../components/navegar'

// #region GraphQL
export const query = graphql`
  {
    allTipsValijas1Yaml {
      edges {
        node {
          nombre
          origen {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
// #endregion
const TipsValijas1 = ({ data, location }) => {
  // eslint-disable-next-line no-unused-vars
  const toFluid = nombre => {
    const edges = data.allTipsValijas1Yaml.edges
    const found = edges.find(x => x.node.nombre === nombre)
    const fluid = found.node.origen.childImageSharp.fluid
    return fluid
  }
  return (
    <Marco>
      <Tips>
        <Container maxWidth="xl">
          <Minipaginas>
            <Minipagina>
              <FotoConLeyenda
                zoom
                fluid={toFluid('imgblogtvalijas1valijaconcinta4ruedasjpg')}
                title="Valija con una cinta para identificarla y las maravillosas 4 rueditas, 360 grados."
                leyenda="Valija con una cinta para identificarla y las maravillosas 4 rueditas, 360 grados."
              />
            </Minipagina>
            <Minipagina>
              <TextoEnMinipagina>
                <h2>¿Cuál es mi valija? Socorro!!!</h2>
                <p>
                  Te puede sorprender pero sucede. Sí, que entre tantas valijas que hay en la cinta
                  transportadora haya varias sino iguales por lo menos muy parecidas a las tuyas.
                </p>
                <p>
                  Hay una solución muy práctica. Atarles en la manija alguna cinta, cuanto más rara
                  mejor. Si es brillante y con colores fluor ¡genial! la vas a poder identificar a
                  lo lejos y lo antes posible. Eso conlleva gran tranquilidad a tu corazón! creeme,
                  ja, ja. Y no solamente porque las reconoces sino porque otra persona sabe que no
                  les pertencen.
                </p>
                <p>Anécdota de porqué es importante que tus valijas se distingan de las otras.</p>
                <p>
                  ¡Porque otra persona se puede llevar por error la tuya! Y sucede, sí. No es
                  intento de robo. Es que uno se confunde fácilmente de valija ¡Muchas veces he
                  tomado una que no era la mía y la he tenido que volver a subir a la cinta
                  transportadora! A veces, no me acordaba bien cómo era la mía porque la acababa de
                  comprar!!! Y tiene en cuenta que no soy la única despistada que anda por ahí
                  suelta.
                </p>
                <p>
                  Una amiga tuvo que quedarse dos horas y medias en Ezeiza para poder recuperar su
                  valija que se había llevado otra señora por error. Y eso, porque ella dijo: "¡sin
                  mi valija no me voy!", pretendían que se fuera y volviera al día siguiente a
                  buscarla!!!
                </p>
                <p>Con una identificación vistosa evitas estos disgustos.</p>
                <p> ¿Cómo elijo la valija? </p>
                <p>
                  Imprescindible de 4 rueditas. Me cambió la vida usar ese tipo de valijas. Las
                  podés llevar cómodamente en cualquier posición. Arrastrarla o empujarla, ir
                  cambiando todo el tiempo de posición, hasta patearla hacia adelante que sigue
                  solita!!!
                </p>
                <p>
                  Y semirígida, porque si llueve no se moja el contenido. Tiene su contra y sí, con
                  las de telas siempre tenía un lugarcito más para agregar alguna cosita y me
                  gustaba poder usar los bolsillos externos para eso. Aunque puede ser de tela
                  impermeable, gastando bastante más, claro.
                </p>
                <p>
                  En cuanto a llevar cartera … uhm, no, mejor llevar una pequeña mochila, te deja
                  las manos libres.
                </p>
              </TextoEnMinipagina>
            </Minipagina>
          </Minipaginas>
          <Navegar location={location} />
          <Comentarios />
          <EspaciadorVertical altura={36} />
        </Container>
      </Tips>
    </Marco>
  )
}
export default TipsValijas1
